import React from "react";
import "../HomePage.css";

export default function WatchUs(props) {
  const scrollToTextUs = () => {
    const target = document.getElementById("home-textus");
    if (target) {
      const y = target.offsetTop;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  return (
    <div id="home-watch" className="home-section">
      <h1>Watch Us</h1>

      {/* 
      <blockquote
        className="tiktok-embed"
        cite="https://www.tiktok.com/@strutman_lane_band/video/7150311610974883114"
        data-video-id="7150311610974883114"
        style={{ maxWidth: "max-content", minWidth: "325px" }}
      >
        <section>
          <a
            target="_blank"
            title="@strutman_lane_band"
            href="https://www.tiktok.com/@strutman_lane_band?refer=embed"
          >
            @strutman_lane_band
          </a>
          attach us to your car we’ll get u free food every time
        </section>
      </blockquote>
      */}

      <div className="yt-vid">
        <iframe
          src="https://www.youtube.com/embed/XJ7-zziaksc"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <div
        className="scroll-down-arrow-4"
        onClick={scrollToTextUs}
        role="button"
        aria-label="Scroll to Text Us"
      />
    </div>
  );
}

