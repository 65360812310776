import React from "react";

import crowd2 from "../utils/atlantiscrowd.jpg";

import "./AboutPage.css";

export default function AboutPage(props) {
  return (
    <div className="about-page">
      <div className="page-wrapper about">
        <div className="page-container">
          <img className="pic" src={crowd2} />

          <div className="splash">
            <div className="home-section bio">
              <h2>About Us</h2>
              <div>
                From the outskirts of DC, a group of individuals with a mutual love of funk, pop, and
                soul came together to breathe sensuality into original music. With each 
                release, Strutman Lane infuses rhythm guitar hooks, warm vocals, and 
                driving sax lines that cater pocket grooves to the soul. Strutman Lane 
                is charging into 2025 with a sold-out show at the Atlantis, an East Coast tour, 
                and a headlining gig at the 9:30 Club this July
                <br />
                <br />
                For better or for worse, Strutman Lane will make you rethink
                your life choices.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-wrapper about">
        <div className="page-container">
         <div id = "meet-band">
          <h1>Meet The Band</h1>
          <div className="flex member-grid">
            <div className="band-member">
              <a
                href="https://www.instagram.com/adamlanda/"
                target="_blank"
                rel="noopener noreferrer"
                className="headshot _adam"
              >
                <div className="role-overlay">
                  <div>Guitar / Vocals</div>
                  <div>@adamlanda</div>
                </div>
              </a>
              <h3 className="member-name">Adam Landa</h3>
            </div>

            <div className="band-member">
              <a
                href="https://www.instagram.com/ashley__huber/"
                target="_blank"
                rel="noopener noreferrer"
                className="headshot _ashley"
              >
                <div className="role-overlay">
                  <div>Vocals</div>
                  <div>@ashley__huber</div>
                </div>
              </a>
              <h3 className="member-name">Ashley Huber</h3>
            </div>

            <div className="band-member">
              <a
                href="https://www.instagram.com/the.jazzhole/"
                target="_blank"
                rel="noopener noreferrer"
                className="headshot _evan"
              >
                <div className="role-overlay">
                  <div>Bass</div>
                  <div>@the.jazzhole</div>
                </div>
              </a>
              <h3 className="member-name">Evan Williamson</h3>
            </div>

            <div className="band-member">
              <a
                href="https://www.instagram.com/absolutely_based/"
                target="_blank"
                rel="noopener noreferrer"
                className="headshot _chris"
              >
                <div className="role-overlay">
                  <div>Sax</div>
                  <div>@absolutely_based</div>
                </div>
              </a>
              <h3 className="member-name">Chris Lawrence</h3>
            </div>

            <div className="band-member">
              <a
                href="https://www.instagram.com/alecaldred/"
                target="_blank"
                rel="noopener noreferrer"
                className="headshot _alec"
              >
                <div className="role-overlay">
                  <div>Trumpet</div>
                  <div>@alecaldred</div>
                </div>
              </a>
              <h3 className="member-name">Alec Aldred</h3>
            </div>

            <div className="band-member">
              <a
                href="https://www.instagram.com/cobysiegel/"
                target="_blank"
                rel="noopener noreferrer"
                className="headshot _coby"
              >
                <div className="role-overlay">
                  <div>Guitar / Vocals</div>
                  <div>@cobysiegel</div>
                </div>
              </a>
              <h3 className="member-name">Coby Siegel</h3>
            </div>

            <div className="band-member">
              <a
                href="https://www.instagram.com/jackgrubermusic/"
                target="_blank"
                rel="noopener noreferrer"
                className="headshot _jack"
              >
                <div className="role-overlay">
                  <div>Keys</div>
                  <div>@jackgrubermusic</div>
                </div>
              </a>
              <h3 className="member-name">Jack Gruber</h3>
            </div>

            <div className="band-member">
              <a
                href="https://www.instagram.com/henrygodfreymusic/"
                target="_blank"
                rel="noopener noreferrer"
                className="headshot _henry"
              >
                <div className="role-overlay">
                  <div>Percussion</div>
                  <div>@henrygodfreymusic</div>
                </div>
              </a>
              <h3 className="member-name">Henry Godfrey</h3>
            </div>

          </div>
        </div>
      </div>
    </div>
   </div>
  );
}

