import React from "react";

import { TextUs } from "./homecomponents";

import "./ContactPage.css";

export default function ContactPage(props) {
  return (
    <div className="page-wrapper contact">
      <div className="page-container">
        <div className="home-section">
          <h1>Contact Us</h1>
          <div className="text-us">
            For booking or general inquiries email us at strutmanlane@gmail.com, or send us a text at +1 (844) 233 3979 to get the latest news from Strutman Lane!
          </div>

          {/* <TextUs /> */}
        </div>
      </div>
    </div>
  );
}
