import React from "react";
import { Link } from "react-router-dom";

import splash from "../../utils/3cbf1bb3-53a4-4b6f-a676-8895ad7bdc1a.JPG";

import "../HomePage.css";

export default function Splash(props) {
  const scrollToNextSection = () => {
    const target = document.getElementById("home-press");
    if (target) {
      const y = target.offsetTop;
      window.scrollTo({ top: y-192, behavior: "smooth" });
    }
  };

  return (
    <>
      <img src={splash} className="splash-pic" />

      <div className="home-section splash flex">
        <div className="splash-text">
          <h2>New EP Out Now!</h2>

          <a
            href="https://open.spotify.com/album/30DEyQFN2tugyjzjIOaaA0?si=YmjkgdprQEeAtscrSWk9QQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button>Stream Now</button>
          </a>
        </div>

        <div
          className="scroll-down-arrow-1"
          onClick={scrollToNextSection}
          role="button"
          aria-label="Scroll to Press Preview"
        />
      </div>
    </>
  );
}

