import React from "react";
import "../HomePage.css";

export default function StreamUs(props) {
  const scrollToWatchUs = () => {
    const target = document.getElementById("home-watch");
    if (target) {
      const y = target.offsetTop;
      window.scrollTo({ top: y-150, behavior: "smooth" });
    }
  };

  return (
    <div id="stream-us" className="home-section">
      <h1>Stream Us</h1>
      <iframe
        className="spotify-playlist"
        src="https://open.spotify.com/embed/playlist/2jJhw5rD4ES0OYxVo5wQrQ?utm_source=generator"
        width="100%"
        height="380"
        frameBorder=""
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      ></iframe>

      <div
        className="scroll-down-arrow-3"
        onClick={scrollToWatchUs}
        role="button"
        aria-label="Scroll to Watch Us"
      />
    </div>
  );
}
