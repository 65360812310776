import React from "react";

import "../PressPage.css";

const press = [
  {
    link: "https://washingtoncitypaper.com/article/756900/strutman-lane-are-hitting-overdrive/",
    org: "Washington City Paper",
    quote: "Strutman Lane Are Hitting Overdrive",
  },
  {
    link: "https://www.followingbackstage.com/strutman-lane-closes-out-first-tour-with-electrifying-middle-east-show/",
    org: "Following Backstage",
    quote: "Infectious grooves, tight harmonies, and undeniable chemistry",
  },
];

export default function PressPreview(props) {
  const scrollToStreamUs = () => {
    const target = document.getElementById("home-stream");
    if (target) {
      const y = target.offsetTop;
      window.scrollTo({ top: y-200, behavior: "smooth" });
    }
  };

  return (
    <div id="press-preview" className="home-section">
      {press.map((p) => (
        <div key={p.link} className="press-quote _preview">
          <h2>
            <q>{p.quote}</q>
          </h2>
          <h3>{p.org}</h3>
          <a target="_blank" rel="noopener noreferrer" href={p.link}>
            {p.link}
          </a>
        </div>
      ))}

      <div
        className="scroll-down-arrow-2"
        onClick={scrollToStreamUs}
        role="button"
        aria-label="Scroll to Stream Us"
      />
    </div>
  );
}

