import React from "react";

import "./HomePage.css";

import {
  Splash,
  PressPreview,
  SeeUs,
  WatchUs,
  StreamUs,
  Media,
  TextUs,
} from "./homecomponents";

export default function HomePage(props) {
  return (
    <div className="page-wrapper _home">
      <div className="page-container">
        <div id="home-splash">
          <Splash />
        </div>

        <div id="home-press">
          <PressPreview />
        </div>

        <div id="home-stream">
          <StreamUs />
        </div>

        <div id="home-watch">
          <WatchUs />
        </div>

        {/* <Media /> */}

        <div id="home-textus">
          <TextUs />
        </div>
      </div>
    </div>
  );
}
