import React from "react";

import "../HomePage.css";

export default function TextUs(props) {
  return (
    <div className="home-section">
      <h1>Contact Us</h1>
      <div className="text-us">
        For booking or general inquiries email us at strutmanlane@gmail.com, or send us a text 
        at +1 (844) 233 3979 to get the latest news from Strutman Lane!
      </div>
    </div>
  );
}
